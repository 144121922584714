/** FORMS GLOBAL STYLES */
.form-control {
  border: 1px solid #ced4da;
  outline: initial !important;
  background: #f8f9fa;
  color: #47404f;
  border-radius: 5px;
}
.input-group-text {
  background-color: #f8f9fa;
}

.form-control:invalid {
  border-color: #ff9191;
}

.form-control:focus {
  color: #665c70;
  background-color: #fff;
  border-color: #a679d2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 51, 153, 0.25);
}
.checkbox,
.radio {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: #dee2e6;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    width: 4px;
    bottom: 0;
    margin: auto;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.checkbox-primary input:checked ~ .checkmark {
  background-color: #639 !important;
}
.bg-primary {
  background-color: #639 !important;
}
/** FORMS GLOBAL STYLES */

/** NGB TOAST STYLE */
.toast {
    box-shadow: none;
    background: none;
    border: none;
  }
  /** NGB TOAST STYLE */
  /** NGB ALERT STYLE */
  .alert {
    background: #fff;
    border-radius: 10px;
    .close {
      font-size: 1.2195rem;
    }
    .close:not(:disabled):not(.disabled):focus,
    .close:not(:disabled):not(.disabled):hover {
      color: #000;
      text-decoration: none;
      opacity: 0.75;
      outline: 0;
    }
  }
  .alert-card {
    border: none;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1),
      inset 0 2px 0 0 #ced4da;
  }
  .alert-card.alert-danger {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1),
      inset 0 2px 0 0 #d22346;
  }
  /** NGB ALERT STYLE */
  
  /** BUBBLE CIRCLE */
  .spinner-bubble-primary {
    color: #639;
  }
  .spinner-bubble {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    display: inline-block;
    font-size: 8px;
    margin: 30px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: 1.3s linear infinite bubble-circle;
    animation: 1.3s linear infinite bubble-circle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner-bubble.inline {
    position: relative;
    top: 0%;
    left: 0%;
  }
  @keyframes bubble-circle {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 0, 2em 2em 0 0.2em,
        0 3em 0 0, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0,
        0 3em 0 0.2em, -2em 2em 0 0, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 0, -3em 0 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 0, -3em 0 0 0, -2em -2em 0 0.2em;
    }
  }
  /** BUBBLE CIRCLE */

  /** CHECKBOOK SLIDER**/
.switch {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  height: 16px;

  span:not(.slider) {
    position: relative;
    top: -2px;
    cursor: pointer;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  input:checked + .slider {
    background-color: #639;
  }
  input:checked + .slider {
    background-color: #639;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    width: 42px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #dee2e6;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: -1px;
    bottom: -4px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
}
/** CHECKBOOK SLIDER**/
